body {
  background-color: #000;
}
a {
  text-decoration: none;
}
@import url("https://fonts.googleapis.com/css2?family=Cinzel&family=Lato:wght@100;200;300;400;500;700&display=swap");
@font-face {
  font-family: "Baske1"; /*Can be any text*/
  src: local("BASKE1"), url("./fonts/BASKE1.ttf") format("truetype");
}
@font-face {
  font-family: "Baske9"; /*Can be any text*/
  src: local("BASKE9"), url("./fonts/BASKE9.ttf") format("truetype");
}
@font-face {
  font-family: "Baske10"; /*Can be any text*/
  src: local("BASKE10"), url("./fonts/BASKE10.ttf") format("truetype");
}
@font-face {
  font-family: "Baskerville"; /*Can be any text*/
  src: local("baskervi"), url("./fonts/baskervi.ttf") format("truetype");
}
@font-face {
  font-family: "BaskervilleBoldBT"; /*Can be any text*/
  src: local("BaskervilleBoldBT"),
    url("./fonts/BaskervilleBoldBT.ttf") format("truetype");
}
@font-face {
  font-family: "BaskervilleBoldItalicBT"; /*Can be any text*/
  src: local("BaskervilleBoldItalicBT"),
    url("./fonts/BaskervilleBoldItalicBT.ttf") format("truetype");
}
@font-face {
  font-family: "BaskervilleBT"; /*Can be any text*/
  src: local("BaskervilleBT"),
    url("./fonts/BaskervilleBT.ttf") format("truetype");
}
@font-face {
  font-family: "BaskervilleItalicBT"; /*Can be any text*/
  src: local("BaskervilleItalicBT"),
    url("./fonts/BaskervilleItalicBT.ttf") format("truetype");
}
@font-face {
  font-family: "Lato"; /*Can be any text*/
  src: local("Lato"), url("./fonts/Lato.ttf") format("truetype");
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
